import React from 'react'
import axios from 'axios'
import './app.css'
import { useDropzone } from 'react-dropzone'
import PromisePool from 'es6-promise-pool'

const reducer = (state, action) => {
  if (action === 'increment') return { ...state, progress: state.progress + 1 }
  else return { progress: 0, total: action }
}

const App = () => {
  const [disabled, setDisabled] = React.useState(false)
  const [progress, dispatch] = React.useReducer(reducer, { progress: 0, total: 0 })
  const onDrop = React.useCallback(files => {
    setDisabled(true)
    dispatch(files.length)
    return axios
      .post('/sign/generate', { files: files.map(f => ({ name: f.name, contentType: f.type })) })
      .then(({ data }) => {
        const producer = function*() {
          for (let i = 0; i < files.length; i++) {
            yield axios
              .put(data.urls[i].url, files[i], {
                headers: { 'Content-Type': files[i].type },
              })
              .then(() => dispatch('increment'))
          }
        }
        return new PromisePool(producer(), 3).start().then(() => {
          setDisabled(false)
          window.alert('Finished Uploading')
        })
      })
      .catch(() => {})
      .then(() => {
        setDisabled(false)
        dispatch(0)
      })
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled })

  return (
    <div className="container">
      <h2>File Upload</h2>
      <div className="break" />
      <div {...getRootProps()} className={`dropzone ${isDragActive || disabled ? 'active' : ''}`}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <div className="break" />
      {progress.total > 0 && (
        <progress value={progress.progress} max={progress.total}>
          {Math.floor((progress.progress * 100) / progress.total)}%
        </progress>
      )}
    </div>
  )
}
export default App
